.List {
    min-width: 200px;
}

.ListItem {
    margin: 10px 0 !important;
    padding-left: 20px !important;
}

.Add {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
}

.Add > span {
    font-size: 10px;
    vertical-align: top;
    margin-left: 4px;
    color: red;
    font-weight: bold;
}