body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  -webkit-appearance: none;
}

.leaflet-container {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}
.leaflet-tooltip-right:before { 
  border: none !important;
}