
.MenuButton {
    height: 32px;
    margin-left: -12px;
    margin-right: 20px;
    display: none !important;
    color: #fb3126 !important;
}

.NavLink {
    margin: 10px 16px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
}

.Login {
    letter-spacing: 2px;
}


@media only screen and (max-width: 768px) {
    .NavLink {
        display: none;
    }
}