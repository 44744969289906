.Container {
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 40px 0;
    margin-top: 120px;
}

.Icon {
    width: 308px;
}

.Text {
    font-size: 50px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fe5700;
    margin: 0;
    margin-top: 20px;
}

.Text2 {
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1b2c32;
    margin: 12px;
}

.Back {
    border-radius: 16px;
    background-color: #fe5700;
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    padding: 10px 30px;
}

.Link {
    text-decoration: none;
}

.Link:hover {
    text-decoration: none;

}

.Link:focus {
    text-decoration: none;

}
.Link:visited {
    text-decoration: none;

}
.Link:active {
    text-decoration: none;
}
