.Root {
    display: flex;
    flex-direction: column;
    /* overflow-x: hidden; */
}

.Content {
    flex: 1 0 auto;
    background-color: #fff;
    min-height: 100vh;
}


@media only screen and (max-width: 480px) {
    .Content {
        min-height: calc(100vh - 56px);
    }
}

